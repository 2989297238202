.widget-container {
    display: flex;
}

.widget-container > * {
    height: 400px;
    padding: 10px;
    width: 90%;
}

.dx-scrollview-content {
    min-height: 380px;
}